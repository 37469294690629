import React from "react";
import "./Prompt.css";
import Shows from "../Shows/Shows.fc";
import Console from "../Console/Console.fc";
import { IntroScreen } from "Game/partials/Intro";
import { Link } from "react-router-dom";

const Prompt = () => {
  return (
    <div className="Prompt">
      <Console />
      <header className="__header __capsule">
        <IntroScreen />
        <h1 hidden>Curse Words Web Portal</h1>
        <Shows />
        <h2>
          New Song "Big Wave Dreaming" out!!!
          <a
            href="https://record-union.intercom-clicks.com/via/e?ob=PG3lVNgoptaKJ52vhD6ly%2BxpE4mmi1AnGLzoiGqcLEOs34eS4nRce6uxjD%2Br4nfL&h=4c31a1017e78035ec1cb74a453906a3a1fc3faad-vbxba027_13148000600300"
            target="_blank"
          >
            Pre-save on Spotify
          </a>{" "}
          or listen now on{" "}
          <a href="https://dontsaycursewords.bandcamp.com/" target="_blank">
            bandcamp.
          </a>
        </h2>
        <p>
          Delve into the lore of our latest album by immersing yourself in our{" "}
          <Link to="/game">online game!</Link> A sci-fi romp in the style of a
          cave adventure game.
        </p>
        <p>
          Check out one of our shows or hit us up on all our social medias
          listed in the nav. Also check out our{" "}
          <Link to="/epk">About page (Press Kit)</Link> to learn more about the
          band.
        </p>
      </header>
    </div>
  );
};

export default Prompt;
