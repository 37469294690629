import React from "react";

const IntroScreen = ({ game }: { game?: boolean }) => (
  <pre className="iwtcot-banner">
    <code>
      {`░░░░░░░░░░░░░░░░░░░░▒█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░`}
      <br />
      {`░░░░░░░░░░░▀█▀░▀█▀░░▒█░░▒█░▄▀▀▄░█▀▀░░▀█▀░█░█░█▀▀░░░░░░░░░░`}
      <br />
      {`░░░░░░░░░░░▒█░░░█░░░▒█▒█▒█░█▄▄█░▀▀▄░░░█░░█▀█░█▀▀░░░░░░░░░░`}
      <br />
      {`▄▀▀▄░░░░░░░▄█▄░░▀░░░░▀▄▀▄▀░▀░░▀░▀▀▀░░░▀░░▀░▀░▀▀▀░░░░░░░░░░`}
      <br />
      {`█░░░▄░░█░█▀▀▄░█▀▀░█▀▀░█▀▄░░▄▀▀▄░█▀░░▀▀█▀▀░▀░░█▀▄▀█░█▀▀░█▀▀`}
      <br />
      {`█░░░█░░█░█▄▄▀░▀▀▄░█▀▀░█░█░░█░∠█░█▀░░░▒█░░░█▀░█░▀░█░█▀▀░▀▀▄`}
      <br />
      {`▀▄▄▀░▀▀▀░▀░▀▀░▀▀▀░▀▀▀░▀▀░░░░▀▀░░▀░░░░▒█░░▀▀▀░▀░░▒▀░▀▀▀░▀▀▀`}
      <br />
      {`░░░${
        !game ? "CURSE WORDS - DC PUNK ROCK" : "░░░░░░░░░░░░░░░░░░░░░░░░░░"
      }░░░░░░░░▒█░░░░░░░░░░░░░░░░░░░`}
      <br />
    </code>
  </pre>
);
export { IntroScreen };
